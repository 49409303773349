<template>

  <div class="card">

    <div class="card-header header-elements-inline" >
      <h5 class="card-title">Size Creation</h5>
      <div class="header-elements">
        <div class="list-icons">
          <a class="list-icons-item" data-action="reload"></a>
          <a class="list-icons-item" data-action="remove" @click="closeWindow"></a>
        </div>
      </div>
    </div>

    <div class="card-body">
      <form action="#">

        <!--<div class="form-group row">-->
          <!--<label class="col-md-2 col-form-label font-weight-semibold">Name:</label>-->
          <!--<div class="col-md-10">-->
            <!--<input  id="txtname" type="text" class="form-control" placeholder="Enter Size Name here..." maxlength="100" v-if="fabsize" v-model="fabsize.name" >-->
          <!--</div>-->
        <!--</div>-->

        <div class="form-group row">
          <label class="col-md-2 col-form-label font-weight-semibold">Width:</label>
          <div class="col-md-4">
            <input type="number" class="form-control" placeholder="Enter width here..." v-if="fabsize" v-model="fabsize.width">
          </div>

          <label class="col-md-2 col-form-label font-weight-semibold">Length:</label>
          <div class="col-md-4">
            <input type="number" class="form-control" placeholder="Enter length here..." v-if="fabsize" v-model="fabsize.length">
          </div>
        </div>

        <div class="form-group row">
          <label class="col-md-2 col-form-label font-weight-semibold">Height:</label>
          <div class="col-md-4">
            <input type="number" class="form-control" placeholder="Enter height here..." v-if="fabsize" v-model="fabsize.height">
          </div>

          <label class="col-md-2 col-form-label font-weight-semibold">Unit:</label>
          <div class="col-md-4">
            <select class="form-control" v-if="fabsize" v-model="fabsize.unit">
              <option value="1">Mt</option>
              <option value="2">CM</option>
              <option value="3">Inch</option>
            </select>

          </div>

        </div>

      </form>
    </div>

    <div class="card-footer">
      <div class="text-right" >
        <button type="button" class="btn btn-primary" @click="saveDocument" >Save<i class="icon-paperplane ml-2"></i></button>
      </div>
    </div>
  </div>

</template>

<script>
  import FabButton from '@/components/core/FabButton.vue'
  import {userService} from '@/store/auth-header.js'
  export default {
    name: 'SizeForm',
    data () {
      return {
        fabsize: JSON.parse('{"id":"","code":0,"nature":0,"name":"","p_name":"","width":0,"length":0,"height":0,"unit":1}')
      }
    },
    props: {
      mysize: {
        type: Object,
        default: () => JSON.parse('{"id":"","code":0,"nature":0,"name":"","p_name":"","width":0,"length":0,"height":0,"unit":1}')
      }
    },
    beforeMount () {

      this.fabsize = this.mysize; // save props data to itself's data and deal with tms
    },
    component: {
      FabButton
    },
    created () {
      this.$data.size = JSON.parse('{"id":"","code":0,"nature":0,"name":"","p_name":"","width":0,"length":0,"height":0,"unit":1}');
    },
    mounted () {
      $('#txtname').focus();
    },
    methods: {
      closeWindow() {
        this.$parent.$parent.closeModal();
      },
      saveDocument(){
        let self = this;
        self.$data.fabsize.width = parseFloat(self.$data.fabsize.width);
        self.$data.fabsize.length = parseFloat(self.$data.fabsize.length);
        self.$data.fabsize.height = parseFloat(self.$data.fabsize.height);
        self.$data.fabsize.unit = parseInt(self.$data.fabsize.unit);

        self.$data.fabsize.name = self.$data.fabsize.width;

        if (self.$data.fabsize.unit == 3){
          self.$data.fabsize.name = self.$data.fabsize.name + '"';
        }else {
          self.$data.fabsize.name = self.$data.fabsize.name +  (self.$data.fabsize.unit ==2 ? ' CM ' : '') ;
        }

        if (self.$data.fabsize.length > 0 ) {
          self.$data.fabsize.name = self.$data.fabsize.name + ' x ' + self.$data.fabsize.length;

          if (self.$data.fabsize.unit == 3){
            self.$data.fabsize.name = self.$data.fabsize.name + '"';
          }else {
            self.$data.fabsize.name = self.$data.fabsize.name +  (self.$data.fabsize.unit ==2 ? ' CM ' : '') ;
          }
        }

        if (self.$data.fabsize.height > 0 ) {
          self.$data.fabsize.name = self.$data.fabsize.name + ' x ' + self.$data.fabsize.height;

          if (self.$data.fabsize.unit == 3){
            self.$data.fabsize.name = self.$data.fabsize.name + '"';
          }else {
            self.$data.fabsize.name = self.$data.fabsize.name +  (self.$data.fabsize.unit ==2 ? ' CM ' : '') ;
          }
        }



        const requestOptions = {
          method:  (self.$data.fabsize.code == 0 ? 'POST' : 'PUT'),
          mode:"cors",
          headers: userService.authHeader(),
          body: JSON.stringify(self.$data.fabsize)
        };

        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/size/`,requestOptions).then(userService.handleResponse).then(function (data) {
          if(data.accepted) {
            swal ( { title: "Success!" ,  text: data.message, icon:  "success"} );
            self.size = JSON.parse('{"id":"","code":0,"nature":0,"name":"","p_name":"","width":0,"length":0,"height":0,"unit":1}');
            self.$parent.$parent.loadData();
            self.closeWindow();
          } else {
            swal ( { title: "Oh noes!" ,  text: data.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        });
      }
    }
  }
</script>

<size scoped>

</size>
