<template>
  <div class="card">
    <div id="toolbar" class="fixed-table-toolbar">
      <div class="float-left">
        <h2 style="padding-left: 10px; padding-top: 10px; padding-bottom: 0px;"> <span class="icon-list"></span>   Size's List</h2>
      </div>
      <div class="float-right search">
        <input  id="txtsearch" class="form-control" type="text" placeholder="Search" autocomplete="off">
      </div>
    </div>

    <div class="table-responsive">
      <table id="size-table"
             class="table table-borderless table-condensed"
             data-search="false"
             data-pagination="true"
             data-show-refresh="false"
             data-show-columns="false"
             data-page-list="[10, 25, 50, 100, ALL]"
             data-show-footer="false"
             data-toggle="context"
             data-target=".context-table">
        <thead>
        <tr>
          <th data-field="id" data-class="d-none">id</th>
          <th data-field="code" data-width="100">Code</th>
          <th data-field="name" >Name</th>
          <th data-field="width" >Width</th>
          <th data-field="length" >Length</th>
          <th data-field="height" >Height</th>
        </tr>
        </thead>
        <tbody>
        </tbody>
        <tfoot>
        </tfoot>
      </table>
    </div>

    <!-- Context Menu -->
    <div id="context-menu" class="context-table">
      <div class="dropdown-menu">
        <a href="#" class="dropdown-item" @click="loadData"><i class="icon-reset" style="color: darkgreen"></i>Refresh</a>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item"> <i class="icon-pencil3" ></i>Modify</a>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item"><i class="icon-blocked text-danger"></i>Remove</a>
      </div>
    </div>
    <!-- End of Context Menu -->

    <!-- Clickable menu -->
    <ul class="fab-menu fab-menu-fixed fab-menu-bottom-right is_stuck" data-fab-toggle="hover" z-index="99">
      <li>
        <a class="fab-menu-btn btn bg-primary-600 btn-float rounded-round btn-icon" @click="showModal">
          <i class="fab-icon-open icon-plus3"></i>
          <i class="fab-icon-close icon-plus3"></i>
        </a>
      </li>
    </ul>
    <!-- /clickable menu -->

    <vmodal name="size-window" transition="nice-modal-fade" :delay="100" :resizable="true" width="50%" height="40%" @before-open="beforeOpen" @before-close="beforeClose">
      <SizeForm v-bind:mysize="size"></SizeForm>
    </vmodal>


  </div>

</template>

<script>
  import SizeForm from '@/components/v1/tms/size/SizeForm.vue'
  import FabButton from '@/components/core/FabButton.vue'
  import moment from 'moment-timezone'
  import {userService} from '@/store/auth-header.js'
  export default {
    name: 'SizeView',
    components: {
      SizeForm,
      FabButton
    },
    data () {
      return {
        mytable: {},
        size: JSON.parse('{"id":"","code":0,"nature":0,"name":"","p_name":"","width":0,"length":0,"height":0,"unit":1}')
      }
    },
    created () {

    },
    mounted () {
      let self = this;

      this.$data.mytable = $('#size-table');

      this.$data.mytable.bootstrapTable();
      $('[data-toggle="popover"]').popover();

      this.$data.mytable.contextmenu({
        target: '#context-menu',
        scopes: 'tbody > tr',
        onItem: function (row, e) {
          var id = $(row.children('*')[0]).text();

          if ($(e.target).text() === 'Modify') {
            self.modifyDocument (id);
          } else if ($(e.target).text() === 'Remove') {
            self.removeDocument(id);
          }
        }
      });

      $('#txtsearch').keyup(function () {
        var val = $.trim($(this).val()).replace(/ +/g, ' ').toLowerCase()
        $('#size-table>tbody>tr').show().filter(function () {
          var text = $(this).text().replace(/\s+/g, ' ').toLowerCase()
          return !~text.indexOf(val)
        }).hide()
      });

      self.loadData();
    },
    methods: {
      closeModal () {
        this.$modal.hide('size-window');
      },
      showModal () {
        this.$modal.show('size-window');
      },
      beforeOpen(){
        console.log('beforeOpen');
      },
      beforeClose(){
        this.size = JSON.parse('{"id":"","code":0,"nature":0,"name":"","p_name":"","width":0,"length":0,"height":0,"unit":1}');
        // this.loadData();
      },
      loadData () {
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        self.$data.mytable.bootstrapTable('load',[]);

        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/size/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.accepted) {
            if(resp.data != null){
              self.$data.mytable.bootstrapTable('load', resp.data);
            }

          } else {
            swal ( { title: "Oh noes!" ,  text: resp.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        });

      },
      modifyDocument (id) {
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        self.$data.size = JSON.parse('{"id":"","code":0,"nature":0,"name":"","p_name":"","width":0,"length":0,"height":0,"unit":1}');

        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/size/${id}/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.accepted) {
            if(resp.data != null ){
              self.$data.size = resp.data;
              self.$modal.show('size-window');
            }
          } else {
            swal ( { title: "Oh noes!" ,  text: resp.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text:  err.toString() , icon:  "error"} );
        });
      },
      removeDocument (id) {
        let self = this;

        self.$data.size.id = id;

        const requestOptions = {
          method: 'DELETE',
          mode:'cors',
          headers: userService.authHeader(),
          body: JSON.stringify(self.$data.size)
        };

        swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          type: 'warning',
          timer: 3000,
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete tms!'
        }).then((result) => {
          if (result.value) {
            fetch(`${size.env.VUE_APP_ROOT_API}v1/tms/size/${id}`,requestOptions).then(userService.handleResponse).then(function (resp) {
              self.$modal.hide('size_window')
              self.loadData();
              swal(
                'Deleted!',
                'Your request has been sizeed',
                'success'
              );
            }).catch(function (err) {
              swal({ title: "Oops", text: err.toString(), type: "error" });
            });
          }
        });
      }
    }
  }
</script>

<style scoped>
  .modal {
    text-align: center;
    padding: 0!important;
  }

  .modal:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  .modal-dialog {
    display: inline-block;
    text-align: left;
    width: 40%;
    vertical-align: middle;
  }
</style>
